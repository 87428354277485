import { render, staticRenderFns } from "./EnvelopesManager.vue?vue&type=template&id=0387f2bc&scoped=true&"
import script from "./EnvelopesManager.vue?vue&type=script&lang=js&"
export * from "./EnvelopesManager.vue?vue&type=script&lang=js&"
import style0 from "./EnvelopesManager.vue?vue&type=style&index=0&id=0387f2bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0387f2bc",
  null
  
)

export default component.exports