<template>
  <div class="is-block is-full-width">
    <div class="level-right envelopes-actions">
      <button
        class="button is-success"
        v-if="canCreateEnvelope"
        @click.stop="$refs.addEnvelopeModal.open"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ $t("envelopes.addEnvelope") }}
      </button>
      <button
        class="button is-primary white"
        @click.stop="$refs.downloadContractTemplateModal.open()"
        cy-data="download-contract-template"
      >
        <i class="fa fa-download"></i>&nbsp;
        {{ $t("contractTemplates.downloadContractTemplate") }}
      </button>
    </div>
    <hr />
    <div
      v-if="envelopes.length > 0"
      class="columns"
      :key="'envelope_' + envelopeId"
    >
      <aside class="menu column is-narrow">
        <ul
          class="menu-list"
          v-for="envelope in envelopes"
          :key="'menu_' + envelope.id"
        >
          <li @click="onClickMenu(envelope.id)">
            <a :class="{ selected: envelope.id === envelopeId }">{{
              envelope.name
            }}</a>
          </li>
        </ul>
      </aside>
      <div class="column left">
        <div class="basic-info" :key="'basinf_' + envelopeId">
          <text-field
            :label="$t('common.name')"
            :value="envelope.name"
            :readonly="true"
          />
          <date-field
            :label="$t('common.date')"
            :value="envelope.createdAt"
            :readonly="true"
          />
        </div>
        <br />
        <documents-table
          v-if="envelopeId"
          ref="doctab"
          :key="'doctab_' + documentsTableKey"
          :objectName="$entitiesName.Envelope"
          :objectId="envelopeId"
          :deletable="canUpdateEnvelope && envelopeIsDraft"
          :showTopButtons="false"
          th-class="has-background-light"
          @updated="updateDocumentsCount"
        />
        <br />
        <validators-table
          v-if="envelopeId"
          :envelopeId="envelopeId"
          :key="'valitab_' + validatorsTableKey"
          @updated="updateValidatorsCount"
        />
      </div>
      <div class="column right">
        <div class="level-right envelope-actions">
          <button
            class="button is-success white"
            v-if="signature && documentsCount > 0 && envelopeIsDraft"
            @click.stop="$refs.sendToValidationModal.open"
            cy-data="send-to-validation"
          >
            {{ $t("envelopes.validation.sendToValidation") }}
          </button>
          <button
            class="button is-primary white"
            v-if="signature && envelopeIsValidated"
            @click.stop="$refs.sendToSignatureModal.open"
            cy-data="send-to-signature"
          >
            {{ $t("envelopes.signature.sendForSignature") }}
          </button>
          <button
            class="button is-primary white"
            v-if="signature && envelopeIsDraft"
            @click.stop="$refs.uploadDocumentModal.open"
            cy-data="upload"
          >
            <i class="fa fa-upload"></i> &nbsp;{{ $t("common.upload") }}
          </button>
          <button
            v-if="canUpdateContract"
            class="button is-danger"
            @click="deleteEnvelope"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <workflow-history
          v-if="envelopeId"
          :key="'workhis_' + workflowHistoryKey"
          v-model="workflowComment"
          :entity="envelope"
          :entityName="WorkflowCodes.ENV.NODE.OBJECT_NAME"
          :entityId="envelopeId"
          :validators-count="validatorsCount"
          @updated="refreshValidatorsTableAndStatusWF"
        />
      </div>
    </div>
    <!-- modals  -->
    <download-contract-template-modal
      ref="downloadContractTemplateModal"
    ></download-contract-template-modal>
    <add-envelope-modal
      ref="addEnvelopeModal"
      :contract-id="contractId"
      @created="refreshPage"
    ></add-envelope-modal>
    <upload-document-modal
      ref="uploadDocumentModal"
      :envelopeId="envelopeId"
      @created="refreshDocumentsTable"
    ></upload-document-modal>
    <send-to-validation-modal
      ref="sendToValidationModal"
      :envelopeId="envelopeId"
      @created="refreshValidatorsTableAndStatusWF"
    ></send-to-validation-modal>
    <send-to-signature-modal
      ref="sendToSignatureModal"
      :envelopeId="envelopeId"
      @created="fetchEnvelopeWorkflowToken"
    ></send-to-signature-modal>
  </div>
</template>

<style scoped>
.menu {
  border-right: 1px solid lightgray;
}
.menu .selected {
  color: white;
  background-color: #f67a15;
}
.level-right .button,
.envelope-actions .button {
  margin-left: 4px;
}
</style>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Workflows as WORKFLOWS } from "../constants/Workflow";

export default {
  name: "envelopes-manager",
  props: ["contractId", "signature"],
  data() {
    return {
      envelopes: [],
      envelopeId: null,
      envelope: {},
      documentsCount: 0,
      workflowComment: "",
      WorkflowCodes: WORKFLOWS,
      documentsTableKey: 0,
      workflowHistoryKey: 0,
      validatorsTableKey: 0,
      validatorsCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
    canCreateEnvelope() {
      return this.getRights(this.$entitiesName.Envelope).create;
    },
    canUpdateEnvelope() {
      return this.getRights(this.$entitiesName.Envelope).update;
    },
    canUpdateContract() {
      return this.getRights(this.$entitiesName.Contract).update;
    },
    envelopeIsDraft() {
      return (
        this.envelope.statusWF &&
        this.envelope.statusWF.node.nodeCode ===
          this.WorkflowCodes.ENV.NODE.DRAFT
      );
    },
    envelopeIsValidated() {
      return (
        this.envelope.statusWF &&
        this.envelope.statusWF.node.nodeCode ===
          this.WorkflowCodes.ENV.NODE.VALIDATED
      );
    },
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    async initializeData() {
      await this.fetchEnvelopes();
      this.envelopeId = parseInt(this.$route.query.envelopeId, 10) || null;
      this.setEnvelopeById();
      if (this.envelopeId) {
        this.switchEnvelope();
      } else {
        this.setFirstEnvelopeAsDefault();
      }
    },
    async fetchEnvelopes() {
      const res = await axios.get(`contract/${this.contractId}/envelopes`);
      this.envelopes = res.data;
    },
    setFirstEnvelopeAsDefault() {
      if (this.envelopes.length > 0) {
        this.envelope = this.envelopes[0];
        this.envelopeId = this.envelope.id;
        this.fetchEnvelopeWorkflowToken();
      }
    },
    setEnvelopeById() {
      if (this.envelopeId) {
        this.envelope =
          this.envelopes.find((item) => item.id === this.envelopeId) || {};
      }
    },
    async switchEnvelope() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, envelopeId: this.envelopeId },
      });
      await this.fetchEnvelopes();
      this.setEnvelopeById();
      this.updateDocumentsCount(0);
      this.fetchEnvelopeWorkflowToken();
    },
    async fetchEnvelopeWorkflowToken() {
      const res = await axios.get(`/wftoken/envelope/${this.envelopeId}`);
      if (res.data) {
        this.$set(this.envelope, "statusWF", res.data);
        this.refreshWorkflowHistory();
      }
    },
    onClickMenu(id) {
      this.envelopeId = id;
      this.switchEnvelope();
    },
    resetMenu() {
      this.envelopeId = null;
    },
    refreshPage(val) {
      this.envelopeId = val;
      this.switchEnvelope();
    },
    async deleteEnvelope() {
      if (this.envelopeId) {
        this.$awn.confirm(this.$t("expressions.deleteEnvelope"), async () => {
          await axios.delete(`/envelope/${this.envelopeId}`);
          this.resetMenu();
          this.fetchEnvelopes();
        });
      }
    },
    refreshDocumentsTable() {
      this.documentsTableKey += 1;
    },
    refreshValidatorsTable() {
      this.validatorsTableKey += 1;
    },
    refreshWorkflowHistory() {
      this.workflowHistoryKey += 1;
    },
    refreshValidatorsTableAndStatusWF() {
      this.refreshValidatorsTable();
      this.fetchEnvelopeWorkflowToken();
    },
    updateDocumentsCount(v) {
      this.documentsCount = v;
    },
    updateValidatorsCount(v) {
      this.validatorsCount = v;
    },
  },
};
</script>
